<template>
  <q-card class="l-wrapper" flat square bordered>
    <q-expansion-item v-model="expanded" class="bg-grey-4">
      <template v-slot:header>
        <q-item-section side>
          <q-btn
            flat
            dense
            icon="arrow_back"
            @click.stop.prevent="router.go(-1)"
          ></q-btn>
        </q-item-section>
        <q-item-section>
          <span class="text-bold text-body1">
            {{ request.detail.name }}
          </span>
          <span> Request Status : {{ request.detail.request_status }} </span>
          <span>
            Sales Status :
            <span v-if="request.detail.sales_status">{{
              request.detail.sales_status
            }}</span>
            <span v-else>-</span>
          </span>
          <span>
            Dibuat : {{ moment(request.detail.created).format("DD MMM YYYY") }}
          </span>
          <span>
            Deadline :
            {{ moment(request.detail.deadline).format("DD-MMM-YYYY") }}
          </span>
        </q-item-section>

        <q-item-section side>
          <div class="row items-center q-gutter-xs">
            <q-btn
              round
              flat
              dense
              size="md"
              color="primary"
              icon="build"
              @click.stop.prevent="
                router.push(`/app/development/form/${this.$route.params.id}`)
              "
              v-can="['SALES']"
            >
              <q-tooltip>
                Edit Data
              </q-tooltip>
            </q-btn>
          </div>
        </q-item-section>
      </template>
      <q-card class="bg-grey-3 text-black">
        <q-card-section>
          <q-markup-table
            class="my-normal-table"
            flat
            style="border: none !important;"
            wrap-cells
          >
            <tr>
              <td>Customer</td>
              <td>:</td>
              <td>{{ request.detail.customer_name }}</td>
            </tr>
            <tr v-if="request.detail?.rnd_name">
              <td>PIC</td>
              <td>:</td>
              <td>{{ request.detail.rnd_name }}</td>
            </tr>
            <tr v-if="request.detail?.rnd_name">
              <td>Diproses</td>
              <td>:</td>
              <td>
                {{
                  moment(request.detail.responded).format("HH:MM, DD-MMM-YYYY")
                }}
              </td>
            </tr>
            <tr>
              <td>Tipe</td>
              <td>:</td>
              <td>{{ request.detail.type }}</td>
            </tr>
            <tr>
              <td>Potential Qty</td>
              <td>:</td>
              <td>{{ thousand(request.detail.potential_qty) }}</td>
            </tr>
            <tr>
              <td>Target Price</td>
              <td>:</td>
              <td>{{ rupiah(request.detail.target_price) }}</td>
            </tr>
            <tr>
              <td>Request Note</td>
              <td>:</td>
              <td>{{ request.detail.request_note }}</td>
            </tr>
            <tr v-if="request.detail?.development_end">
              <td>Development Done</td>
              <td>:</td>
              <td>
                {{
                  moment(request.detail.development_end).format("DD-MMM-YYYY")
                }}
              </td>
            </tr>
            <tr v-if="request.detail?.observation_end">
              <td>Observation Done</td>
              <td>:</td>
              <td>
                {{
                  moment(request.detail.observation_end).format("DD-MMM-YYYY")
                }}
              </td>
            </tr>
            <tr v-if="request.detail.request_status == 'DECLINED'">
              <td>Declined Note</td>
              <td>:</td>
              <td>{{ request.detail.declined_note }}</td>
            </tr>
          </q-markup-table>
        </q-card-section>
        <q-card-actions
          align="stretch"
          v-if="request.detail.request_status == 'REQUEST'"
        >
          <q-btn
            class="full-width q-mb-sm"
            color="positive"
            label="ACCEPT"
            v-can="['RND_SUPERVISOR']"
            @click="acceptRequest('accept')"
          ></q-btn>
          <q-btn
            class="full-width"
            color="negative"
            label="REJECT"
            v-can="['RND_SUPERVISOR']"
            @click="acceptRequest('reject')"
          ></q-btn>
        </q-card-actions>
        <q-card-actions
          align="stretch"
          v-else-if="request.detail.request_status == 'PROCESS'"
        >
          <q-btn
            class="full-width"
            color="primary"
            label="Update Request Status"
            @click="changeStatus"
            v-can="['RND']"
          ></q-btn>
        </q-card-actions>
        <q-card-actions
          align="stretch"
          v-else-if="
            request.detail.request_status == 'FAILED' ||
              request.detail.request_status == 'SUCCESS'
          "
        >
          <div
            class="full-width"
            v-if="request.detail.sales_status == 'OBSERVATION'"
          >
            <q-btn
              class="full-width q-mb-sm"
              color="primary"
              label="Update Qty"
              v-can="['ADMIN']"
              @click="updatePo"
            ></q-btn>
            <q-btn
              class="full-width"
              color="secondary"
              label="FINISH OBSERVATION"
              v-can="['ADMIN']"
              @click="finishObserve"
            ></q-btn>
          </div>
          <q-btn
            v-if="request.detail.sales_status == 'GRADING'"
            class="full-width q-mb-sm"
            color="primary"
            label="Update Sales Status"
            v-can="['RND']"
            @click="updateSalesStatus"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-expansion-item>
    <template v-if="route.meta.user.is_admin != '1'">
      <q-tabs
        dense
        v-model="tab"
        class="text-black"
        @update:model-value="getList"
        align="justify"
      >
        <q-tab
          :class="tab == 'chat' ? 'bg-blue-8' : 'bg-primary'"
          name="chat"
          label="Chat"
        />
        <q-tab
          :class="tab == 'record' ? 'bg-blue-8' : 'bg-primary'"
          name="record"
          label="Record"
          :disable="
            request.detail.request_status == 'REQUEST' ||
              request.detail.request_status == 'DECLINED' ||
              route.meta.user.is_rnd != '1'
          "
        />
      </q-tabs>
      <q-tab-panels v-model="tab">
        <q-tab-panel class="q-pa-none" name="chat">
          <q-card-section>
            <q-scroll-area
              ref="chatAreaRef"
              class="l-grow q-pa-md bg-grey-3"
              style="height: calc(100vh - 350px)"
            >
              <div v-for="(item, i) in chatList" :key="i">
                <q-chat-message
                  :label="
                    item.date == 'TODAY' || item.date == 'YESTERDAY'
                      ? item.date
                      : moment(item.date).format('DD MMM YYYY')
                  "
                />

                <q-chat-message
                  text-html
                  v-for="val in item.chats"
                  :key="val.id"
                  :name="val.username"
                  :bg-color="val.is_sent ? 'green-4' : 'blue-4'"
                  :text="[`${val.content}`]"
                  :stamp="val.created"
                  :sent="val.is_sent"
                />
              </div>
            </q-scroll-area>
          </q-card-section>
          <q-card-section class="q-py-none">
            <q-input
              bottom-slots
              outlined
              type="textarea"
              placeholder="Type here..."
              v-model="chat"
              rows="3"
            >
            </q-input>
          </q-card-section>
          <q-card-actions class="q-px-md" align="stretch">
            <q-btn
              class="full-width no-border-radius"
              color="primary"
              label="KIRIM PESAN"
              @click="sendChat"
              :disable="chat == ''"
            ></q-btn>
          </q-card-actions>
        </q-tab-panel>
        <q-tab-panel class="q-pa-none" name="record">
          <q-card-section>
            <q-scroll-area
              ref="recordAreaRef"
              class="l-grow q-pa-md bg-grey-3"
              style="height: calc(100vh - 330px)"
            >
              <div v-for="(item, i) in listRecord" :key="i">
                <q-chat-message
                  :label="
                    item.date == 'TODAY' || item.date == 'YESTERDAY'
                      ? item.date
                      : moment(item.date).format('DD MMM YYYY')
                  "
                />

                <q-chat-message
                  text-html
                  v-for="val in item.records"
                  :key="val.id"
                  :name="val.username"
                  :bg-color="val.is_sent ? 'green-4' : 'blue-4'"
                  :stamp="
                    `${val.created} &nbsp - &nbsp Last Edited : ${moment(
                      val.last_edit
                    ).format('DD/MM/YY HH:mm:ss')}`
                  "
                  :sent="val.is_sent"
                  :text="[val.content]"
                  @click="showEditDilaog(val)"
                >
                </q-chat-message>
              </div>
            </q-scroll-area>
          </q-card-section>
          <q-card-section class="q-py-none">
            <div class="q-mb-sm" style="max-width: 100vw;">
              <ckeditor
                id="myEditor"
                :editor="options.editor"
                v-model="options.editorData"
                :config="options.editorConfig"
              ></ckeditor>
            </div>
            <q-uploader
              :factory="uploader"
              ref="uploadRef"
              hide-upload-btn
              label="Upload File"
              accept="image/* , .rar , .zip"
              flat
              bordered
              :max-files="1"
              style="width: 100%"
              @removed="request.detail.filename = null"
            />
          </q-card-section>
          <!-- <q-card-section>
          <a class="text-h6 text-bold">
            Preview :
          </a>
          <div
            class="prevent bg-grey-3 q-pa-md"
            v-html="options.editorData"
          ></div>
        </q-card-section> -->
          <q-card-actions class="q-px-md" align="stretch">
            <q-btn
              class="full-width no-border-radius"
              color="primary"
              label="SIMPAN RECORD"
              :disable="options.editorData == ''"
              v-can="['RND']"
              @click="saveRecord"
            ></q-btn>
          </q-card-actions>
        </q-tab-panel>
      </q-tab-panels>
    </template>
    <q-dialog v-model="modalRecord">
      <q-card style="min-width:500px">
        <q-card-section>
          <!-- <div class="bg-grey-3 q-pa-md" v-html="modalRecordData.content"></div> -->
          <ckeditor
            id="myEditor"
            :editor="options.editor"
            v-model="modalRecordData.content"
            :config="options.editorConfig"
          ></ckeditor>
        </q-card-section>
        <q-card-actions align="right">
          <!-- <q-btn
            unelevated
            color="negative"
            label="Hapus"
            @click="delGuard = true"
            v-close-popup
          ></q-btn> -->
          <q-btn
            unelevated
            color="primary"
            label="Edit"
            @click="editRecord"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="delGuard">
      <q-card>
        <q-card-section>Apa Anda yakin ingin menghapus </q-card-section>
      </q-card>
    </q-dialog>
  </q-card>
</template>

<script>
import { computed, reactive, ref, inject, nextTick } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import { useRoute, useRouter } from "vue-router";
import { useQuasar } from "quasar";

import DialogAccepRequest from "../component/DialogAcceptRequest.vue";
import DialogUbahStatus from "../component/DialogUbahStatus.vue";
import DialogUpdateQty from "../component/DialogUpdateQty.vue";
import DialogUpdateSalesStatus from "../component/DialogUpdateSalesStatus.vue";
import DialogFinishRequest from "../component/DialogFinishRequest.vue";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const $q = useQuasar();
    let $http = inject("$http");
    let rupiah = inject("rupiah");
    let thousand = inject("thousand");

    let tab = ref("chat");
    let curUser = ref({});
    let expanded = ref(false);

    onMounted(async () => {
      curUser.value = JSON.parse(JSON.stringify(route.meta.user));
      if (curUser.value.is_admin == "1") {
        expanded.value = true;
      }
      $q.loading.show({ message: "Mohon Tunggu..." });

      await getRequestDetail();
      await getChat();

      $q.loading.hide();
    });

    let request = reactive({
      detail: {},
    });

    let getRequestDetail = async () => {
      let req_id = route.params.id;
      let resp = await $http.get(`/development/detail/${req_id}`);
      request.detail = resp.data;
    };

    let getList = async (value) => {
      $q.loading.show({ message: "Mohon Tunggu..." });
      if (value == "chat") {
        await getChat();
      } else if (value == "record") {
        await getRecord();
      }
      $q.loading.hide();
    };

    let scrollToEnd = async () => {
      if (tab.value == "chat") {
        await scrollChat();
      } else if (tab.value == "record") {
        await scrollRecord();
      }
    };

    let refreshList = async () => {
      if (tab.value == "chat") {
        await getChat();
      } else if (tab.value == "record") {
        await getRecord();
      }
    };

    let uploader = async (files) => {
      let ext = files[0].name.split(".")[1];
      const d = new Date();
      let newname = `developtment_${request.detail.name.replaceAll(
        " ",
        "_"
      )}_${d.getTime()}.${ext}`;
      request.detail.filename = newname;
      var formData = new FormData();
      formData.append("file", files[0]);
      formData.append("nama", newname);

      await $http.post("/upload.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return {};
    };

    let acceptRequest = (tipe) => {
      let bundle = {
        tipe: tipe,
        detail: request.detail,
        req_type: "dev",
        user: JSON.parse(JSON.stringify(curUser.value)),
      };
      $q.dialog({
        component: DialogAccepRequest,
        componentProps: {
          data: bundle,
        },
      }).onOk(async (resp) => {
        let content = "";
        if (tipe == "accept")
          content = `Development Request  untuk item : ${request.detail.name}, Customer : ${request.detail.customer_name} Diterima`;
        if (tipe == "reject")
          content = `Development Request  untuk item : ${request.detail.name}, Customer : ${request.detail.customer_name} Ditolak`;
        let notifPayload = {
          id_from: curUser.value.id,
          id_to: request.detail.id_user,
          router_to: `/app/development/chat/${request.detail.id}`,
          content: content,
        };
        await $http.post(`/notification/add`, notifPayload);

        $q.loading.show({ message: "Mohon Tunggu..." });
        await getRequestDetail();
        await getChat();
        $q.loading.hide();
      });
    };

    let changeStatus = () => {
      $q.dialog({
        component: DialogUbahStatus,
        componentProps: {
          detail: request.detail,
          req_type: "dev",
          user: JSON.parse(JSON.stringify(curUser.value)),
        },
      }).onOk(async (resp) => {
        let notifPayload = {
          id_from: curUser.value.id,
          term: ["is_admin"],
          id_to: request.detail.id_user,
          router_to: `/app/development/chat/${request.detail.id}`,
        };
        if (resp == "SUCCESS") {
          notifPayload.content = `Item : ${request.detail.name}, Customer : ${request.detail.customer_name} dinyatakan Berhasil`;
          if (request.detail.type != "COMPLAINT") {
            notifPayload.content = `${notifPayload.content} dan masuk masa observasi`;
            await $http.post(`/notification/broadcastrole`, notifPayload);
          }
        } else if (resp == "FAILED") {
          notifPayload.content = `Item : ${request.detail.name}, Customer : ${request.detail.customer_name} dinyatakan Gagal!`;
        }
        await $http.post(`/notification/add`, notifPayload);

        $q.loading.show({ message: "Mohon Tunggu..." });
        await getRequestDetail();
        await refreshList();
        $q.loading.hide();
      });
    };

    let updatePo = () => {
      $q.dialog({
        component: DialogUpdateQty,
        componentProps: {
          detail: request.detail,
          user: JSON.parse(JSON.stringify(curUser.value)),
        },
      }).onOk(async (resp) => {
        $q.loading.show({ message: "Mohon Tunggu..." });
        await getRequestDetail();
        await refreshList();
        $q.loading.hide();
      });
    };

    let updateSalesStatus = () => {
      $q.dialog({
        component: DialogUpdateSalesStatus,
        componentProps: {
          detail: request.detail,
          user: JSON.parse(JSON.stringify(curUser.value)),
        },
      }).onOk(async (resp) => {
        let notifPayload = {
          id_from: curUser.value.id,
          id_to: request.detail.id_user,
          router_to: `/app/development/chat/${request.detail.id}`,
          content: `Nilai Sales Telah diberikan untuk item : ${request.detail.name}, Customer : ${request.detail.customer_name}`,
        };
        await $http.post(`/notification/add`, notifPayload);

        $q.loading.show({ message: "Mohon Tunggu..." });
        await getRequestDetail();
        await refreshList();
        $q.loading.hide();
      });
    };

    let finishObserve = () => {
      $q.dialog({
        component: DialogFinishRequest,
        componentProps: {
          detail: request.detail,
          req_type: "dev",
          user: JSON.parse(JSON.stringify(curUser.value)),
        },
      }).onOk(async (resp) => {
        let notifPayload = {
          id_from: curUser.value.id,
          term: ["is_rnd"],
          content: `Item : ${request.detail.name}, Customer : ${request.detail.customer_name} selesai di observasi dan masuk masa GRADING`,
          router_to: `/app/development/chat/${request.detail.id}`,
        };
        await $http.post(`/notification/broadcastrole`, notifPayload);
        router.push("/app/development/list");
      });
    };

    //record area
    let listRecord = ref();
    let uploadRef = ref(null);

    let saveRecord = async () => {
      try {
        await uploadRef.value.upload();
        let payload = {
          id_user: curUser.value.id,
          last_edit: moment().format("YYYY-MM-DD HH:mm:ss"),
          id_request: route.params.id,
          content: options.editorData,
          is_development: 1,
        };

        if (request.detail.filename) {
          if (request.detail.filename != "") {
            // TODO ganti link file saat mau di hosting
            payload.content = `${payload.content} <br><br>Download Attachment <a href="https://forte.rnd.lignosoftware.com/exp_records/${request.detail.filename}" target="_blank" >disini!</a>`;
            // payload.content = `${payload.content} <br><br>Download Attachment <a href="http://localhost/forteapp-rnd/public/exp_records/${request.detail.filename}">disini!</a>`;
          }
        }

        await $http.post("/exp/add-record", payload);
        options.editorData = "";
        $q.notify({
          message: "Experiment Record Berhasil Ditambahkan",
          color: "positive",
        });
        await uploadRef.value.reset();

        $q.loading.show({ message: "Mohon Tunggu..." });
        await getRecord();
        $q.loading.hide();
      } catch (error) {
        $q.notify({
          message: "Experiment Record Gagal Ditambahkan",
          color: "negative",
        });
        console.log("gagal menyimpan record");
        console.log(error);
      }
    };
    let getRecord = async () => {
      let resp = await $http.get(`/exp/list/${route.params.id}/1`);
      for await (let item of resp.data) {
        if (item.date == moment().format("YYYY-MM-DD")) {
          item.date = "TODAY";
        }
        if (
          item.date ==
          moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD")
        ) {
          item.date = "YESTERDAY";
        }
        for await (let record of item.records) {
          if (record.id_user == curUser.value.id) {
            record.is_sent = true;
          } else {
            record.is_sent = false;
          }
          record.created = moment(record.created).format("HH:mm");
          // record.content = unescape(content);
        }
      }
      listRecord.value = resp.data;
      nextTick(() => {
        scrollToEnd();
      });
    };

    const recordAreaRef = ref(null);
    let scrollRecord = () => {
      nextTick(() => {
        let resp = recordAreaRef.value.getScroll();
        recordAreaRef.value.setScrollPosition("vertical", 99999);
        resp = recordAreaRef.value.getScroll();
      });
    };

    let options = reactive({
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        // The configuration of the editor.
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "link",
            "|",
            "outdent",
            "indent",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "blockQuote",
            "|",
            "undo",
            "redo",
          ],
        },
      },
    });

    let showEditDilaog = (val) => {
      if (val.id_user != curUser.value.id) return;
      modalRecordData.value = val;
      modalRecord.value = true;
    };

    let editRecord = async () => {
      let payload = {
        id: modalRecordData.value.id,
        content: modalRecordData.value.content,
        last_edit: moment().format("YYYY-MM-DD HH:mm:ss"),
      };
      try {
        await $http.post(`/exp/edit`, payload);
        $q.notify({
          message: "Experiment Record Berhasil Diubah!",
          color: "positive",
        });

        $q.loading.show({ message: "Mohon Tunggu..." });
        await getRecord();
        $q.loading.hide();
      } catch (error) {
        $q.notify({
          message: "Experiment Record Gagal Diubah!",
          color: "negative",
        });
      }
    };

    let delRecord = async () => {};

    let delGuard = ref(false);
    let modalRecord = ref(false);
    let modalRecordData = ref({});

    //chat area

    let chat = ref("");
    let chatList = ref([]);
    let sendChat = async () => {
      let processedChat = chat.value.replaceAll("\n", "<br>");
      let payload = {
        id_user: curUser.value.id,
        id_request: route.params.id,
        content: processedChat,
        is_development: 1,
      };
      try {
        await $http.post("/chat/add", payload);
        await notifChat();
        chat.value = "";

        $q.loading.show({ message: "Mohon Tunggu..." });
        await getChat();
        $q.loading.hide();
      } catch (err) {
        $q.notify({ message: "Pesan Gagal Dikirim", color: "negative" });
        console.log("gagal mengirim chat");
      }
    };

    let notifChat = async () => {
      if (curUser.value.is_sales == 1) {
        let notifPayload = {
          id_from: curUser.value.id,
          term: ["is_rnd"],
          content: `Ada Pesan Baru untuk item : ${request.detail.name}, Customer : ${request.detail.customer_name}`,
          router_to: `/app/development/chat/${request.detail.id}`,
        };
        await $http.post(`/notification/broadcastrole`, notifPayload);
      }
      if (curUser.value.is_manager == 1) {
        let notifPayload = {
          id_from: curUser.value.id,
          term: ["is_rnd"],
          content: `Ada Pesan Baru untuk item : ${request.detail.name}, Customer : ${request.detail.customer_name}`,
          router_to: `/app/development/chat/${request.detail.id}`,
        };
        await $http.post(`/notification/broadcastrole`, notifPayload);

        notifPayload = {
          id_from: curUser.value.id,
          id_to: request.detail.id_user,
          router_to: `/app/development/chat/${request.detail.id}`,
          content: `Ada Pesan Baru untuk item : ${request.detail.name}, Customer : ${request.detail.customer_name}`,
        };
        await $http.post(`/notification/add`, notifPayload);
      }
      if (curUser.value.is_rnd == 1) {
        let notifPayload = {
          id_from: curUser.value.id,
          id_to: request.detail.id_user,
          router_to: `/app/development/chat/${request.detail.id}`,
          content: `Ada Pesan Baru untuk item : ${request.detail.name}, Customer : ${request.detail.customer_name}`,
        };
        await $http.post(`/notification/add`, notifPayload);
      }
    };

    let getChat = async () => {
      let resp = await $http.get(`/chat/list/${route.params.id}/1`);
      for (let item of resp.data) {
        if (item.date == moment().format("YYYY-MM-DD")) {
          item.date = "TODAY";
        }
        if (
          item.date ==
          moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD")
        ) {
          item.date = "YESTERDAY";
        }
        for (let chat of item.chats) {
          if (chat.id_user == curUser.value.id) {
            chat.is_sent = true;
          } else {
            chat.is_sent = false;
          }
          // chat.content = chat.content.split("<br>");
          chat.created = moment(chat.created).format("HH:mm");
        }
      }
      chatList.value = resp.data;
      scrollToEnd();
    };

    const chatAreaRef = ref(null);
    let scrollChat = async () => {
      if (tab.value != "chat") return;
      let resp = await chatAreaRef.value.getScroll();
      chatAreaRef.value.setScrollPosition("vertical", 99999);
    };

    let username = ref("admin");

    return {
      tab,
      route,
      router,
      expanded,
      chat,
      chatList,
      sendChat,
      options,
      saveRecord,
      listRecord,
      request,
      moment,
      rupiah,
      thousand,
      username,
      getList,
      modalRecord,
      modalRecordData,
      recordAreaRef,
      chatAreaRef,
      acceptRequest,
      changeStatus,
      updatePo,
      updateSalesStatus,
      finishObserve,
      showEditDilaog,
      editRecord,
      delRecord,
      delGuard,
      uploader,
      uploadRef,
    };
  },
};
</script>

<style lang="scss">
table,
td,
th {
  border: 1px solid black;
}

table {
  width: 100%;
  border-collapse: collapse;
}
.my-normal-table {
  table,
  td,
  th {
    border: 0px solid red;
  }
}
</style>
