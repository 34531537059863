<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="row items-center justify-between">
        <a class="text-h6">Ubah PO Qty</a>
        <q-btn flat dense icon="close" @click="onCancelClick"></q-btn>
      </q-card-section>
      <q-card-section>
        <span> Jumlah PO terbaru dari Item {{ props.detail.name }} : </span>
        <!-- <q-input outlined type="number" v-model="new_qty"></q-input> -->
        <q-field outlined dense v-model="new_qty" class="bg-white">
          <template
            v-slot:control="{ id, floatingLabel, modelValue, emitValue }"
          >
            <money3comp
              :id="id"
              class="q-field__input"
              :model-value="modelValue"
              @update:model-value="emitValue"
              v-bind="config"
              v-show="floatingLabel"
            ></money3comp>
          </template>
        </q-field>
      </q-card-section>
      <q-card-actions align="stretch">
        <q-btn
          unelevated
          class="full-width"
          color="primary"
          label="SIMPAN PO QTY"
          @click="onOKClick"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { useDialogPluginComponent, useQuasar } from "quasar";
import { inject, onMounted, ref } from "vue";
import { Money3Component } from "v-money3";

export default {
  props: ["detail", "user"],
  components: { money3comp: Money3Component },
  emits: [...useDialogPluginComponent.emits],

  setup(props) {
    const {
      dialogRef,
      onDialogHide,
      onDialogOK,
      onDialogCancel,
    } = useDialogPluginComponent();

    const $http = inject("$http");
    const $q = useQuasar();
    let config = ref({
      thousands: ",",
      decimal: ".",
      precision: 0,
      disableNegative: false,
      disabled: false,
      min: null,
      max: null,
      allowBlank: false,
      minimumNumberOfCharacters: 0,
    });

    onMounted(() => {
      if (!props.detail.name) {
        props.detail.name = props.detail.item_name;
      }
      let temp = JSON.parse(JSON.stringify(props.detail));
      new_qty.value = temp.po_qty;
    });

    let onOKClick = async () => {
      let payload = {
        po_qty: props.detail.po_qty,
        new_qty: new_qty.value,
        cur_user_id: props.user.id,
        id: props.detail.id,
        is_development: 1,
      };

      try {
        await $http.post(`/request/update-po`, payload);
        $q.notify({ message: "PO Qty Berhasil Diubah", color: "positive" });
      } catch (error) {
        $q.notify({ message: "PO Qty Gagal Diubah", color: "negative" });
      }
      onDialogOK();
    };

    let new_qty = ref(0);

    return {
      dialogRef,
      onDialogHide,
      onOKClick,
      onCancelClick: onDialogCancel,
      props,
      new_qty,
      config,
    };
  },
};
</script>
