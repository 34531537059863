<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="row items-center justify-between">
        <a class="text-h6">Ubah Status Sales</a>
        <q-btn flat dense icon="close" @click="onCancelClick"></q-btn>
      </q-card-section>
      <q-card-section>
        <span>
          Ubah Status Sales Berdasarkan Hasil Penelitian
          {{ props.detail.name }} :
        </span>
        <q-markup-table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Keterangan</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Target PO</td>
              <td>{{ thousand(props.detail.potential_qty) }}</td>
            </tr>
            <tr>
              <td>Target Price</td>
              <td>{{ rupiah(props.detail.target_price) }}</td>
            </tr>
            <tr>
              <td>PO Saat Ini</td>
              <td>{{ thousand(props.detail.po_qty) }}</td>
            </tr>
          </tbody>
        </q-markup-table>
      </q-card-section>
      <q-card-section>
        <q-select
          outlined
          stack-label
          :options="optStatus"
          v-model="selStatus"
          label="Nilai Sales"
        ></q-select>
      </q-card-section>
      <q-card-actions align="stretch">
        <q-btn
          unelevated
          class="full-width"
          color="primary"
          label="SIMPAN NILAI SALES"
          @click="onOKClick"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { useDialogPluginComponent, useQuasar } from "quasar";
import { inject, onMounted, ref } from "vue";

export default {
  props: ["detail", "user"],

  emits: [...useDialogPluginComponent.emits],

  setup(props) {
    const {
      dialogRef,
      onDialogHide,
      onDialogOK,
      onDialogCancel,
    } = useDialogPluginComponent();

    const $http = inject("$http");
    const $q = useQuasar();
    const rupiah = inject("rupiah");
    const thousand = inject("thousand");

    onMounted(async () => {
      if (!props.detail.name) {
        props.detail.name = props.detail.item_name;
      }
    });

    let optStatus = ref(["GOOD", "BAD", "FAIR"]);
    let selStatus = ref(null);
    let getOptStatus = async () => {
      let resp = await $http.get(`/request/sales-status`);
      let temp = [];
      for (let item of resp.data) {
        temp.push(item.status);
      }
      optStatus.value = temp;
    };

    let onOKClick = async () => {
      let payload = {
        sales_status: props.detail.sales_status,
        new_status: selStatus.value,
        cur_user_id: props.user.id,
        id: props.detail.id,
        is_development: 1,
      };

      try {
        await $http.post(`/request/update-sales-status`, payload);
        $q.notify({
          message: "Sales Status Berhasil Diubah",
          color: "positive",
        });
      } catch (error) {
        $q.notify({ message: "Sales Status Gagal Diubah", color: "negative" });
      }
      onDialogOK();
    };

    return {
      dialogRef,
      onDialogHide,
      onOKClick,
      onCancelClick: onDialogCancel,
      props,
      thousand,
      rupiah,
      optStatus,
      selStatus,
    };
  },
};
</script>
